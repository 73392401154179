import React, { useContext } from 'react';
import {
  Formik,
  Form,
  FastField,
  ErrorMessage,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Input } from 'components/common';
import {
  Error,
  Center,
  InputField,
  Button,
} from 'components/common/Form/styles';
import { EMAIL_REGEX } from 'util/regex';
import { DriverFormContext, PersonalInfo } from './DriverFormContext';

const PersonalInfoForm: React.FC = () => {
  const formContext = useContext(DriverFormContext);
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        number: '',
        success: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name field is required'),
        email: Yup.string()
          .required('Email field is required')
          .matches(EMAIL_REGEX, 'Email must be valid.'),
        number: Yup.string()
          .required('Phone number field is required.')
          .test('phone-valid', 'Phone number must be valid.', function(value) {
            return !!parsePhoneNumberFromString(value, 'US')?.isValid();
          }),
      })}
      onSubmit={async (
        { name, email, number },
        { setSubmitting, resetForm, setFieldValue }
      ) => {
        const personalInfo: PersonalInfo = {
          name,
          email,
          number,
        };
        try {
          setSubmitting(true);
          formContext.submitPersonalInfo(personalInfo);
          // location.href = `${process.env.GATSBY_PORTAL_BASE_URL}/login`;
        } catch (err) {
          setSubmitting(false);
          setFieldValue('success', false);
          resetForm();
          alert('Something went wrong, please try again!'); // eslint-disable-line
        }
      }}
    >
      <AccountFormForm />
    </Formik>
  );
};

const AccountFormForm: React.FC = () => {
  const formikContext = useFormikContext<PersonalInfo & { success: boolean }>();

  return (
    <Form
      name="driver-personal"
      method="post"
      onSubmit={formikContext.handleSubmit}
    >
      <InputField>
        <Input
          id="name"
          label="Full Name"
          as={FastField}
          type="text"
          name="name"
          component="input"
          aria-label="name"
          placeholder="Full Name*"
          error={formikContext.touched.name && formikContext.errors.name}
          onChange={formikContext.handleChange}
          value={formikContext.values.name}
        />
        <ErrorMessage component={Error} name="name" />
      </InputField>
      <InputField>
        <Input
          id="email"
          label="Email"
          aria-label="email"
          component="input"
          as={FastField}
          type="email"
          name="email"
          placeholder="Email*"
          error={formikContext.touched.email && formikContext.errors.email}
          onChange={formikContext.handleChange}
          value={formikContext.values.email}
        />
        <ErrorMessage component={Error} name="email" />
      </InputField>
      <InputField>
        <Input
          as={FastField}
          label="Phone Number"
          component="input"
          aria-label="phone number"
          id="number"
          type="tel"
          name="number"
          placeholder="Phone Number*"
          error={formikContext.touched.number && formikContext.errors.number}
          onChange={formikContext.handleChange}
          onBlur={(e: any) => {
            formikContext.handleBlur(e);
            const { value } = e.target;
            if (!formikContext.errors.number && value.trim()) {
              const pn = parsePhoneNumberFromString(value, 'US');
              if (pn?.isValid()) {
                formikContext.setFieldValue('number', pn.formatNational());
              }
            }
          }}
          value={formikContext.values.number}
        />
        <ErrorMessage component={Error} name="number" />
      </InputField>
      {formikContext.values.success && (
        <InputField>
          <Center>
            <h4>Your account was successfully created!</h4>
          </Center>
        </InputField>
      )}
      <Center>
        <Button secondary type="submit" disabled={formikContext.isSubmitting}>
          Next
        </Button>
      </Center>
    </Form>
  );
};

export default PersonalInfoForm;
