import React, { useState } from 'react';
import dateFormat from 'dateformat';
import queryString from 'query-string';

export interface PersonalInfo {
  name: string;
  email: string;
  number: string;
}

export interface DriverInfo {
  city: string;
  hours: string;
  weeklyTripCount: string;
  companyPreference: string;
  referralCode: string;
}

interface DriverForm extends PersonalInfo, DriverInfo {}

const emptySignUpForm = {
  name: '',
  email: '',
  number: '',
  city: '',
  hours: '',
  weeklyTripCount: '',
  companyPreference: '',
  isCompFair: '',
  hasHotspot: '',
  date: '',
  referralCode: '',
};

type DriverFormPage = 'personal' | 'driver' | 'success';

export const DriverFormContext = React.createContext<
  DriverForm & {
    page: DriverFormPage;
    submitPersonalInfo: (info: PersonalInfo) => void;
    submitDriverInfo: (info: DriverInfo) => Promise<any>;
  }
>({
  ...emptySignUpForm,
  page: 'personal',
  submitPersonalInfo: (info: PersonalInfo) => null,
  submitDriverInfo: (info: DriverInfo) => new Promise(resolve => resolve()),
});

const DriverFormContextProvider: React.FC = props => {
  const [value, setValue] = useState<DriverForm>(emptySignUpForm);
  const [page, setPage] = useState<DriverFormPage>('personal');

  const submitPersonalInfo = (info: PersonalInfo) => {
    setValue(v => ({ ...v, ...info }));
    setPage('driver');
  };

  const submitDriverInfo = async (info: DriverInfo) => {
    setValue(v => ({ ...v, ...info }));
    const data: any = {
      ...value,
      ...info,
      date: dateFormat(new Date(), 'yyyy-mm-dd hh:MM:ss'),
    };
    const { recaptcha, ...payload } = data;
    if (process.env.GATSBY_DRIVER_FORM_URL) {
      await fetch(
        `${process.env.GATSBY_DRIVER_FORM_URL}?${queryString.stringify(
          payload
        )}`,
        { method: 'GET' }
      );
    } else {
      console.log(
        `${process.env.GATSBY_DRIVER_FORM_URL}?${queryString.stringify(
          payload
        )}`
      );
    }
    await fetch(`${process.env.GATSBY_PORTAL_BASE_URL}/recruit_form`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    setPage('success');
    return;
  };

  return (
    <DriverFormContext.Provider
      value={{ ...value, page, submitPersonalInfo, submitDriverInfo }}
    >
      {props.children}
    </DriverFormContext.Provider>
  );
};

export default DriverFormContextProvider;
