import React from 'react';
import { Background, Title, Wrapper, BackgroundContainer } from './styles';
import { graphql, useStaticQuery } from 'gatsby';

export const Intro: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background/driver.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Wrapper>
      <BackgroundContainer>
        <Background fluid={data.file.childImageSharp.fluid} />
      </BackgroundContainer>
      <Title>
        Earn <span css={{ fontWeight: 'bold' }}>up to $100</span> each month
        while you drive with a Paneau tablet.
      </Title>
    </Wrapper>
  );
};
