import styled from 'styled-components';
import Image from 'gatsby-image';

export const Wrapper = styled.header`
  height: 20rem;
  min-height: 400px;
  left: 0;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  top: 0;
  transition: height 400ms ease-out 300ms;
  width: 100%;
  overflow: hidden;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media (max-width: 560px) {
    position: relative;
    height: 10rem;
  }
`;

export const Background = styled(Image)`
  width: 100%;
  height: auto;
`;

export const Title = styled.h1`
  font-weight: normal;
  top: 5rem;
  right: 0;
  background-color: white;
  padding: 2rem 1rem;
  text-align: center;
  max-width: 1200px;
  width: 75%;
  overflow: hidden;
  transition: transform 500ms ease-in 0ms, color 300ms ease-in 500ms,
    opacity 300ms ease-in 300ms;
  position: absolute;

  @media (max-width: 560px) {
    position: relative;
    width: 100%;
    top: 0;
    padding-top: 0;
  }
`;
