import styled from 'styled-components';

export const ImageRow = styled.div`
  align-items: center;
  margin-top: 2rem;
  text-align: center;

  img {
    width: 100%;
  }
`;
