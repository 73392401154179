import styled from 'styled-components';

export const DriverRow = styled.div`
  @media (max-width: 767px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
  }

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
  }
`;

export const Icon = styled.div<{ rotate?: boolean }>`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 75px;
  text-align: center;
  justify-content: center;
  padding: 0;
  margin-right: 1.5rem;
  flex-shrink: 0;
  background-color: #f4f8fb;

  svg {
    transition: all 200ms ease-in;
    ${props => (props.rotate ? 'transform: rotate(-90deg);' : undefined)}
  }

  &:hover svg {
    transform: ${props =>
      props.rotate ? 'rotate(-90deg) scale(1.25)' : 'scale(1.25)'};
  }
`;
