import React, { useContext } from 'react';
import {
  Formik,
  Form,
  FastField,
  ErrorMessage,
  useFormikContext,
  Field,
} from 'formik';
import * as Yup from 'yup';
import { Input, RadioButtonGroup, RadioButton } from 'components/common';
import {
  Error,
  Center,
  InputField,
  Button,
  RecaptchaField,
} from 'components/common/Form/styles';
import Recaptcha from 'react-google-recaptcha';
import { DriverFormContext, DriverInfo } from './DriverFormContext';

const DriverInfoForm: React.FC = () => {
  const formContext = useContext(DriverFormContext);
  return (
    <Formik
      initialValues={{
        city: '',
        hours: '',
        weeklyTripCount: '',
        companyPreference: '',
        referralCode: '',
        recaptcha: '',
      }}
      validationSchema={Yup.object().shape({
        city: Yup.string().required('Please select one option.'),
        hours: Yup.string().required('Please select one option.'),
        weeklyTripCount: Yup.string().required('Please select one option.'),
        companyPreference: Yup.string().required('Please select one option.'),
        referralCode: Yup.string(),
        recaptcha: Yup.string()
          .required("Please verify you're not a robot!")
          .nullable(),
      })}
      onSubmit={async (data, { setSubmitting, resetForm, setFieldValue }) => {
        const driverInfo: DriverInfo = {
          ...data,
        };
        try {
          setSubmitting(true);
          await formContext.submitDriverInfo(driverInfo);
          // location.href = `${process.env.GATSBY_PORTAL_BASE_URL}/login`;
        } catch (err) {
          setSubmitting(false);
          setFieldValue('success', false);
          resetForm();
          alert('Something went wrong, please try again!'); // eslint-disable-line
        }
      }}
    >
      <AccountFormForm />
    </Formik>
  );
};

const AccountFormForm: React.FC = props => {
  const formikContext = useFormikContext<{
    city: string;
    hours: string;
    weeklyTripCount: string;
    companyPreference: string;
    referralCode: string;
  }>();

  return (
    <Form
      name="business-signup"
      method="post"
      onSubmit={formikContext.handleSubmit}
    >
      <div style={{ marginBottom: '2rem' }}>
        <label htmlFor="city-select" style={{ display: 'block' }}>
          <b>Where do you drive?*</b>
        </label>
        <select
          id="city-select"
          name="city"
          value={formikContext.values.city}
          onChange={formikContext.handleChange}
          onBlur={formikContext.handleBlur}
          style={{
            display: 'block',
            height: '3rem',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          <option disabled value="" label="Where do you drive?" />
          <option value="YAYAREA">SF Bay Area, CA</option>
          <option value="la">Los Angeles, CA</option>
          <option value="sd">San Diego, CA</option>
          <option value="seattle">Seattle, WA</option>
          <option value="portland">Portland, OR</option>
          <option value="chi">Chicago, Il</option>
          <option value="austin">Austin, TX</option>
          <option value="nyc">New York, NY</option>
          <option value="dc">Washington D.C.</option>
          <option value="miami">Miami, FL</option>
          <option value="other">other</option>
        </select>
        {formikContext.errors.city && formikContext.touched.city && (
          <ErrorMessage component={Error} name="city" />
        )}
      </div>

      <RadioButtonGroup
        name="hours"
        label="How many hours do you drive per week?*"
        value={formikContext.values.hours}
        error={formikContext.errors.hours}
        touched={formikContext.touched.hours}
      >
        <RadioOption name="hours" value="0-10" label="0-9 hours" />
        <RadioOption name="hours" value="10-20" label="10-19 hours" />
        <RadioOption name="hours" value="20-30" label="20-29 hours" />
        <RadioOption name="hours" value="30-40" label="30-39 hours" />
        <RadioOption name="hours" value="40+" label="40+ hours" />
      </RadioButtonGroup>

      <RadioButtonGroup
        name="weeklyTripCount"
        label="How many trips per week do you complete on average?*"
        value={formikContext.values.weeklyTripCount}
        error={formikContext.errors.weeklyTripCount}
        touched={formikContext.touched.weeklyTripCount}
      >
        <RadioOption name="weeklyTripCount" value="1-10" label="1-10 trips" />
        <RadioOption name="weeklyTripCount" value="11-20" label="11-20 trips" />
        <RadioOption name="weeklyTripCount" value="21-35" label="21-35 trips" />
        <RadioOption name="weeklyTripCount" value="36-50" label="36-50 trips" />
        <RadioOption name="weeklyTripCount" value="50+" label="50+ trips" />
      </RadioButtonGroup>

      <RadioButtonGroup
        name="companyPreference"
        label="How often do you drive with Uber/Lyft?*"
        value={formikContext.values.companyPreference}
        error={formikContext.errors.companyPreference}
        touched={formikContext.touched.companyPreference}
      >
        <RadioOption
          name="companyPreference"
          value="Everyday"
          label="Every day"
        />
        <RadioOption
          name="companyPreference"
          value="Weekday"
          label="Weekdays"
        />
        <RadioOption
          name="companyPreference"
          value="Weekends"
          label="Weekends"
        />
        <RadioOption name="companyPreference" value="Other" label="Other" />
      </RadioButtonGroup>

      <InputField>
        <Input
          label="Referral Code"
          as={FastField}
          type="text"
          name="referralCode"
          component="input"
          aria-label="referral code"
          placeholder="Referral Code"
          error={
            formikContext.touched.referralCode &&
            formikContext.errors.referralCode
          }
          onChange={formikContext.handleChange}
          value={formikContext.values.referralCode}
        />
        <ErrorMessage component={Error} name="referralCode" />
      </InputField>

      {formikContext.values.city &&
        formikContext.values.hours &&
        formikContext.values.weeklyTripCount &&
        formikContext.values.companyPreference && (
          <RecaptchaField>
            <FastField
              component={Recaptcha}
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              name="recaptcha"
              onChange={(value: any) =>
                formikContext.setFieldValue('recaptcha', value)
              }
            />
            <Center>
              <ErrorMessage component={Error} name="recaptcha" />
            </Center>
          </RecaptchaField>
        )}

      <Center>
        <Button secondary type="submit" disabled={formikContext.isSubmitting}>
          Sign Up
        </Button>
      </Center>
    </Form>
  );
};

export const RadioOption: React.FC<{
  name: string;
  value: string;
  label: string;
}> = ({ name, value, label }) => {
  const formikContext = useFormikContext<{
    city: string;
    hours: string;
    weeklyTripCount: string;
    companyPreference: string;
    referralCode: string;
  }>();
  return (
    <Field
      component={RadioButton}
      name={name}
      id={`${name}-${value}`}
      selectedValue={(formikContext.values as any)[name]}
      value={value}
      label={label}
      onChange={(e: any) => formikContext.setFieldValue(name, e.target.value)}
    />
  );
};

export default DriverInfoForm;
