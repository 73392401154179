import React, { useContext } from 'react';
import { FadeTransition, SuccessPage } from 'components/common';
import { Button } from 'components/common/Form/styles';
import { Wrapper } from './styles';
import { SwitchTransition } from 'react-transition-group';
import DriverFormContextProvider, {
  DriverFormContext,
} from './DriverFormContext';
import PersonalInfoForm from './PersonalInfoForm';
import DriverInfoForm from './DriverInfoForm';

export const Form = () => (
  <Wrapper>
    <h2>Drive With Us</h2>
    <p>Tell us about yourself, and we'll contact you to get started.</p>
    <DriverFormContextProvider>
      <FormPage />
    </DriverFormContextProvider>
  </Wrapper>
);

const FormPage: React.FC = () => {
  const { page, hours, city } = useContext(DriverFormContext);
  const showCalendlyButton =
    (hours == '30-40' || hours == '40+') && city == 'YAYAREA';
  return (
    <SwitchTransition>
      <FadeTransition key={page}>
        {page === 'personal' ? (
          <PersonalInfoForm key="personalInfo" />
        ) : page === 'driver' ? (
          <DriverInfoForm key="driverInfo" />
        ) : (
          <SuccessPage showIcon={!showCalendlyButton}>
            <h3>
              Thank you for your interest!
              <br />
              Someone from our team will reach out shortly.
            </h3>
            {showCalendlyButton && (
              <p>
                One last step! Please schedule an appointment to receive the
                tablet.
                <Button
                  css={{ marginTop: '1rem', width: '280px' }}
                  secondary
                  onClick={() => {
                    if (process.env.GATSBY_DRIVER_CALENDLY)
                      location.href = process.env.GATSBY_DRIVER_CALENDLY;
                  }}
                >
                  Schedule Appointment
                </Button>
              </p>
            )}
          </SuccessPage>
        )}
      </FadeTransition>
    </SwitchTransition>
  );
};
